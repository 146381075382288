import React, {useState} from "react";

export default function useFullscreen(elRef) {
    const [fullscreenElement, setFullscreenElement] = useState(null)

    const setFullscreen = (setFullscreen = true) => {
        if (!elRef.current?.requestFullscreen)
            return;

        if (setFullscreen)
            elRef.current.requestFullscreen().then()
        else (
            closeFullscreen()
        )
    };

    React.useLayoutEffect(() => {
        let handler = () => setFullscreenElement(getBrowserFullscreenElement());
        document.addEventListener("fullscreenchange", handler)

        return () => {
            document.removeEventListener("fullscreenchange", handler)
        };
    });

    return {
        isFullscreen: !!fullscreenElement,
        setFullscreen,
        ref: elRef,
        supportsFullscreen: setFullscreen && elRef.current && elRef.current.requestFullscreen};
}

function closeFullscreen() {
    if (document.exitFullscreen) {
        document.exitFullscreen();
    } else if (document.mozCancelFullScreen) { /* Firefox */
        document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
        document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) { /* IE/Edge */
        document.msExitFullscreen();
    }
}

function getBrowserFullscreenElement() {
    if (typeof document.fullscreenElement !== "undefined") {
        return document.fullscreenElement;
    } else if (typeof document.mozFullScreenElement !== "undefined") {
        return document.mozFullScreenElement;
    } else if (typeof document.msFullscreenElement !== "undefined") {
        return document.msFullscreenElement;
    } else if (typeof document.webkitFullscreenElement !== "undefined") {
        return document.webkitFullscreenElement;
    } else {
        return null
    }
}