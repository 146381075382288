import React, {useEffect, useRef} from 'react'
import useParticipantStatus from '../hooks/useParticipantStatus'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faMicrophoneSlash, faVideoSlash} from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'
import useFullscreen from '../hooks/useFullscreen'
import useTrack from '../hooks/useTrack'
import {SpeedComponentIcon} from '../BandwidthPage'

const StyledContainer = styled.div`
    position: relative;
    height: 100%;
    margin: 0 0.5em;

    video {
        width: auto;
        height: 100%;

        object-fit: contain;
        object-position: center top;
        background: black;
        display: block;
    }
`

const VideoMutedFilter = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.73);
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        width: 30% !important;
        height: auto !important;
    }
`

const StyledStatus = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.8em;

    background: rgba(244, 243, 243, 0.8);
    padding: 3px 8px;

    *:first-child {
        flex: 1;
    }
    svg {
        margin-left: 5px;
    }
`

export const Publication = ({publication, participant, className = '', participantInfo, onClick, ...props}) => {
    const track = useTrack(publication)
    const ref = useRef()
    const hasTrack = track
    const name = participantInfo?.name ?? 'Gjest'
    const downloadSpeed = participantInfo?.download ?? 0
    const uploadSpeed = participantInfo?.upload ?? 0

    const {muted} = useParticipantStatus(participant)
    const {isFullscreen, setFullscreen} = useFullscreen(ref)

    useEffect(() => {
        if (!track) return

        const el = ref.current
        el.muted = false
        track.attach(el)
        return () => {
            track.detach(el)
        }
    }, [track, participant])

    if (track && track.kind === 'audio') return <audio ref={ref} autoPlay={true} />

    return (
        <StyledContainer className={className + ' videoContainer'} {...props}>
            <video ref={ref} autoPlay={false} onDoubleClick={() => setFullscreen(!isFullscreen)} onClick={onClick} />
            {!hasTrack && (
                <VideoMutedFilter>
                    <FontAwesomeIcon color={'white'} icon={faVideoSlash} />
                </VideoMutedFilter>
            )}
            <StyledStatus
                onClick={onClick}
                className={' status'}
                style={{color: muted || !hasTrack ? '#C21C1C' : '#062A30'}}>
                <span><strong>{name}</strong></span>
                {muted && <FontAwesomeIcon icon={faMicrophoneSlash} />}
                {downloadSpeed && uploadSpeed ? (
                    <SpeedComponentIcon
                        downloadSpeed={downloadSpeed}
                        uploadSpeed={uploadSpeed}
                        size={'1x'}
                        color={muted || !hasTrack ? '#C21C1C' : '#062A30'}
                    />
                ) : null}
            </StyledStatus>
        </StyledContainer>
    )
}
export default Publication
