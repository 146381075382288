import React, {useCallback, useState, useLayoutEffect} from 'react'
import {Route, Switch, useHistory} from 'react-router-dom'
import './App.css'
import Room from './Room'
import Goodbye from './Goodbye'
import Lobby from './Lobby'
import ConnectCode from './ConnectCode'
import 'bootstrap/dist/css/bootstrap.min.css'
import ConnectRoom from './ConnectRoom'
import BandwidthPage from './BandwidthPage'
import ErrorPage from './ErrorPage'
import ErrorBoundary from './assets/Components/ErrorBoundary'
import ConnectDoctor from "./ConnectDoctor";

export const DataContext = React.createContext({})

const App = () => {
    const history = useHistory()
    const [error, setError] = useState()
    const [data, setData] = useState({})

    const onSubmitCode = code => {
        history.push(`/c/${code}`)
    }

    useLayoutEffect(() => {
        let vh = window.innerHeight * 0.01
        document.documentElement.style.setProperty('--vh', `${vh}px`)

        window.addEventListener('resize', () => {
            let vh = window.innerHeight * 0.01
            document.documentElement.style.setProperty('--vh', `${vh}px`)
        })
    }, [])

    const onError = useCallback(
        error => {
            setError(error)
            history.replace(localStorage.getItem('uri') || '/')
        },
        [setError, history]
    )

    let title = "Nefle Video"
    if (data && data.office && data.doctor)
        title = `${data.office}: Dr. ${data.doctor}`

    return (
        <DataContext.Provider value={{data, setData}}>
            <ErrorBoundary errorHandler={onError}>
                <Theme title={title}>
                    {error ? (
                        <ErrorPage error={error} clearError={() => setError(null)} />
                    ) : (
                        <Switch>
                            <Route exact path='/'>
                                <Lobby handleSubmit={onSubmitCode} onError={onError} />
                            </Route>
                            <Route exact path={'/c/:code'}>
                                <ConnectCode onError={onError} />
                            </Route>
                            <Route exact path={'/r/:roomId'}>
                                <ConnectRoom onError={onError} />
                            </Route>
                            <Route exact path={'/l/:doctor'}>
                                <ConnectDoctor onError={onError} />
                            </Route>
                            <Route exact path={'/authenticate'}>
                                <ConnectDoctor.Auth onError={onError} />
                            </Route>
                            <Route exact path={'/auth'}>
                                <ConnectRoom.Auth onError={onError} />
                            </Route>
                            <Route exact path={'/room'}>
                                <Room onError={onError} />
                            </Route>
                            <Route exact path={'/goodbye'}>
                                <Goodbye />
                            </Route>
                            <Route exact path={'/bandwidth'}>
                                <BandwidthPage />
                            </Route>
                        </Switch>
                    )}
                </Theme>
            </ErrorBoundary>
        </DataContext.Provider>
    )
}
export default App

const Theme = ({children, title}) => {
    return (
        <>
            <nav style={{backgroundColor: '#062A30', color: 'white'}} className='navbar navbar-expand-lg'>
                <span className='navbar-brand'>{title}</span>
            </nav>
            {children}
        </>
    )
}
