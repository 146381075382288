import React from 'react';
import Publication from "./Publication";
import usePublications from "../hooks/usePublications";

const Participant = ({ participant, onSelectVideo, mainVideo, participantInfo, ...props }) => {
    const {publication: selectedPublication} = mainVideo
    const publications = usePublications(participant)

    return publications.filter(p => p !== selectedPublication).map(p => (
        <Publication
            participantInfo={participantInfo}
            key={p.trackSid}
            publication={p}
            participant={participant}
            onClick={() => onSelectVideo({participant, publication: p})}
            {...props}
        />
    ))
};
export default Participant;


