import {useCallback, useContext, useEffect, useState} from "react";
import {DataContext} from "../App";

const fetchStatus = async (state, token) => {
    const response = await fetch(process.env.REACT_APP_EPORTAL_URL + '/api/nefle_video/status' + state, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })

    if (response.status === 200)
        return response.json()

    switch (response.status) {
        case 401: throw new Error("Du har ikke tilatelse til å logge på dette rommet.")
        case 403: throw new Error("Rommet er stengt. Vennligst kom tilbake ved møte tidspunktet.")
        case 404: throw new Error("Koden finnes ikke.")
        default: throw new Error("Feilmelding fra tjener: " + response.statusText)
    }
}


const useRoomStatus = (onError, room) => {
    const [status, setStatus] = useState("Vennligst vent...");
    const [participants, setParticipants] = useState({});
    const {data: {token} = {}} = useContext(DataContext)


    const refresh = useCallback(() => {
        if (!token)
            return

        const state = room ? "?state=" + room.state : ""
        fetchStatus(state, token, onError).then(
            json => {
                if (status !== json.message)
                    setStatus(json.message)

                if (JSON.stringify(participants) !== JSON.stringify((json.participants)))
                    setParticipants(json.participants ?? {})
            },
            err => onError(err)
        )
    }, [token, onError, participants, status, room])

    useEffect(() => {
        const interval = setInterval(() => {
            refresh()
        },10000)

        return () => {clearInterval(interval)}
    },[refresh])

    return {status, participants, refresh}
}
export default useRoomStatus