import React, {useContext, useEffect} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {DataContext} from './App'
import {Col, Container, Row} from 'react-bootstrap'
import fetchToken from './hooks/fetchVideoToken'
import useLogin from './hooks/useLogin'
import useBrowserSupport from './hooks/useBrowserSupport'

const ConnectRoom = ({onError}) => {
    const {roomId} = useParams()
    const history = useHistory()
    localStorage.setItem('roomId', roomId)
    const valid = useBrowserSupport(onError)
    const {login, token} = useLogin(onError)

    useEffect(() => {
        if (!valid)
            return

        if (!token) login()

        localStorage.setItem('uri', '/r/' + roomId)
        history.replace('/auth')
    }, [token, history, login, roomId, valid])

    return (
        <Container fluid>
            <Row>
                <Col md={{span: 6, offset: 3}}>
                    <div style={{marginTop: '40%'}}>
                        <h2>
                            <strong>Kobler til...</strong>
                        </h2>
                        <div style={{textAlign: 'center'}}>
                            <p>Autentiserer...</p>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}
export default ConnectRoom

const Auth = ({onError}) => {
    const {setData} = useContext(DataContext)
    const roomId = localStorage.getItem('roomId')
    const {token} = useLogin(onError, true)
    const history = useHistory()
    const valid = useBrowserSupport(onError)

    useEffect(() => {
        if (!token || !valid) return

        let mounted = true
        fetchToken({roomId, token, path: '/api/nefle_video/token_new'})
            .then(data => {
                if (!mounted) return

                setData(data)
                history.push('/bandwidth')
            })
            .catch(onError)

        return () => {
            mounted = false
        }
    }, [token, history, onError, roomId, setData, valid])

    return (
        <Container fluid>
            <Row>
                <Col md={{span: 6, offset: 3}}>
                    <div style={{marginTop: '40%'}}>
                        <h2>
                            <strong>Kobler til...</strong>
                        </h2>
                        <div style={{textAlign: 'center'}}>
                            <p>Henter videorommet...</p>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}
ConnectRoom.Auth = Auth
