import {useEffect, useState} from "react";
import crypto from "crypto";
import jwtDecode from "jwt-decode";

function base64URLEncode(str) {
    return str.toString('base64')
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=/g, '');
}

function sha256(buffer) {
    return crypto.createHash('sha256').update(buffer).digest();
}

export const getCurrentToken = () => {
    const currentJwt =  localStorage.getItem("nefle-video-jwt")
    if (!currentJwt || currentJwt === "undefined")
        return false

    const decodedToken = currentJwt ? jwtDecode(currentJwt) : {}

    const now = Date.now() / 1000;
    const isLoggedIn = decodedToken && decodedToken.exp > now;
    if (!isLoggedIn){
        localStorage.removeItem("nefle-video-jwt")
        return false
    }

    return currentJwt
}

const fetchToken = async (code, code_verifier) => {
    const hostname = window.location.host
    const protocol = window.location.protocol
    const path = window.location.pathname
    const redirect_uri = new URL(`${protocol}//${hostname}${path}`)

    const token = getCurrentToken();
    console.log("Current token", token)

    if (token){
        return token
    }


    const response = await fetch(process.env.REACT_APP_EPORTAL_URL + process.env.REACT_APP_OIDC_TOKEN, {
        method: 'POST',
        body: JSON.stringify({
            "client_id": process.env.REACT_APP_OIDC_CLIENT_ID,
            "grant_type": "authorization_code",
            "code_challenge_method": "S256",
            redirect_uri,
            code,
            code_verifier
        }),
        headers: {
            'Content-type': 'application/json'
        }
    })
    const json = await response.json()
    localStorage.setItem("nefle-video-jwt", json.id_token)
    return json.id_token
}

const useLogin = (onError, handleAuth = false, scope = "nefle-video") => {
    const [token, setToken] = useState(getCurrentToken)

    const logout = () => {
        localStorage.removeItem("nefle-video-jwt")
        setToken(false)
        console.log("Logged out")
    }

    const login = (redirectUrl = undefined) => {
        const codeVerifier = crypto.randomBytes(64).toString("hex")
        const codeChallenge = base64URLEncode(sha256(codeVerifier))
        localStorage.setItem("OIDC_CODE_VERIFIER", codeVerifier)

        const hostname = window.location.host
        const protocol = window.location.protocol
        redirectUrl = redirectUrl ?? new URL(`${protocol}//${hostname}/auth`)

        const loginUrl = process.env.REACT_APP_EPORTAL_URL + process.env.REACT_APP_OIDC_AUTHORIZE
        const authorizeUrl = new URL(loginUrl)
        authorizeUrl.searchParams.append("response_type","code")
        authorizeUrl.searchParams.append("scope",`openid ${scope}`)
        authorizeUrl.searchParams.append("client_id",process.env.REACT_APP_OIDC_CLIENT_ID)
        authorizeUrl.searchParams.append("redirect_uri",redirectUrl.toString())
        authorizeUrl.searchParams.append("code_challenge_method",'S256')
        authorizeUrl.searchParams.append("code_challenge",codeChallenge)

        window.location.href = authorizeUrl.toString()
    }

    useEffect(() => {
        if ((window.location.pathname !== "/auth" && window.location.pathname !== "/authenticate") || !handleAuth)
            return

        const href = new URL(window.location.href)
        const code = href.searchParams.has("code") ? href.searchParams.get("code") : false
        const code_verifier = localStorage.getItem("OIDC_CODE_VERIFIER")

        if (!code_verifier || !code){
            return
        }

        localStorage.removeItem("OIDC_CODE_VERIFIER")
        href.searchParams.delete("code")

        fetchToken(code, code_verifier)
            .then(token => setToken(token))
            .catch(error => onError(error))
    }, [onError, handleAuth]) // only check hash when app loads

    return {login, logout, token}
}
export default useLogin
