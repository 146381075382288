import StackdriverErrorReporter from "stackdriver-errors-js"


export const errorHandler = new StackdriverErrorReporter();
if (process.env.NODE_ENV !== "development")
    errorHandler.start({
        key: process.env.REACT_APP_GOOGLE_ERROR_REPORTING_KEY,
        projectId: 'skil-196709',
        service: 'nefle-video',
        version: process.env.NODE_ENV
        // Other optional arguments can also be supplied, see below.
    });