import React from 'react'
import styled from 'styled-components'
import useBrowserSupport from './hooks/useBrowserSupport';

const StyledMainDiv = styled.div`
  height: calc(100vmin - 56px);
  display: flex;
  align-items: center;
`

const StyledForm = styled.form`
  width: 75%;
  max-width: fit-content;
  margin: 0 auto;

  div.codeInput {
    width: 100%;
    margin-bottom: 1em;

    label {
      display: block;
      margin-bottom: 0.3em;
    }

    input {
      display: block;
      border-radius: 6px;
      border: none;
      width: 14ch;
      background: repeating-linear-gradient(90deg, dimgrey 0, dimgrey 0.9ch, transparent 0, transparent 2ch) 0 100%/100%
        2px no-repeat;
      color: #a61e7b;
      font: 4ch consolas, monospace;
      letter-spacing: 1ch;
      padding: 0;
      margin: 1em 1em 1em 0;
    }

    input:focus {
      outline: none;
      color: #a61e7b;
    }
  }
`

const Lobby = ({ handleSubmit, onError }) => {
  const [code, setCode] = React.useState('')
  useBrowserSupport(onError)

  const onSubmit = (e) => {
    e.preventDefault()
    handleSubmit(code)
  }

  return (
    <StyledMainDiv>
      <StyledForm onSubmit={onSubmit}>
        <div style={{ paddingTop: '4em' }} className={'codeInput'}>
          <h3 style={{ textAlign: 'left' }}>
            <strong>Nefle | Videokonsultasjon</strong>
          </h3>
          <label htmlFor='room'>Legg inn kode for å delta på videokonsultasjon med din lege.</label>
          <input
            autoFocus
            className={'codeInput'}
            maxLength='7'
            type='text'
            id='code'
            value={code}
            onChange={(e) => setCode(e.target.value)}
            required
          />
        </div>
        <div style={{ textAlign: 'right' }}>
          <button style={{ background: '#A61E7B' }} type='submit'>
            Logg inn
          </button>
        </div>
      </StyledForm>
    </StyledMainDiv>
  )
}

export default Lobby
