import {isSupported} from 'twilio-video'
import UAParser from 'ua-parser-js'
import IgnoredError from "../assets/IgnoredError";

const useBrowserSupport = (onError, userAgent = null) => {
    /*
       https://github.com/3rd-Eden/useragent#readme
       - [X] på iOS, krev minst Safari 12.1 og krev at brukeren bytter nettleser hvis de bruker en alternativ nettleser (f.eks chrome eller firefox, eller Facebook Web)
       - [X] på Android, krev Chrome eller Firefox, og krev at brukeren bytter nettleser hvis de bruker en alternativ nettleser
       - [X] Sjekk for getUserMedia
       - [X] Ikke feil hvis brukeren ikke kan dele skjerm (https://console.cloud.google.com/errors/CN_hpJOsw4G0dA?service=nefle-video&time=P7D&project=skil-196709&folder&organizationId)
     */
    const parser = new UAParser(userAgent)
    const os = parser.getOS()
    const browser = parser.getBrowser()
    const link = window.location.href

    if (os.name === 'iOS' && !(browser.name === 'Safari' || browser.name === 'Mobile Safari')) {
        onError(
            new IgnoredError(`Vennligst åpne ${link} i Safari og prøv igjen. ${browser.name} er ikke støttet på iOS.`)
        )

        return false
    }

    if (os.name === 'iOS' && browser.name === 'Safari' && Number(os.version) < 12.2) {
        onError(new IgnoredError(`Vennligst oppgrader Safari til en versjon nyere en 12.1 og prøv igjen.`))
        return false
    }

    // Don't worry about Android....
/*    if (os.name === 'Android' && !(browser.name === 'Firefox' || browser.name === 'Chrome')) {
        onError(
            new IgnoredError(
                `Vennligst åpne ${link} i Chrome eller Firefox og prøv igjen. ${browser.name} er ikke støttet på Android.`
            )
        )
        return false
    }*/

    if (
        typeof navigator !== 'undefined' &&
        (typeof navigator.mediaDevices === 'undefined' ||
            typeof navigator.mediaDevices.getUserMedia === 'undefined' ||
            !isSupported)
    ) {
        onError(
            new IgnoredError(`${browser.name} har ikke støtte for videokonsultasjon, vennligst prøv en annen nettleser.`)
        )
        return false
    }

    return true
}
export default useBrowserSupport
