import {Container} from "react-bootstrap";
import React, {useContext, useEffect, useState} from "react";
import {DataContext} from "./App";
import {useHistory} from "react-router-dom";
import Network from "network-js/dist/network";
import styled from "styled-components";
import WaitingRoom from "./assets/image/waitingroom_background.jpg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSignalAlt1, faSignalAlt2, faSignalAlt3, faSignalAlt} from "@fortawesome/pro-duotone-svg-icons";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

const TEST_DURATION_SECONDS = 4

const MainContainer = styled(Container)`
    padding-right: 0;
    padding-left: 0;
    margin-right: 0;
    margin-left: 0;
`

const WaitingDiv = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    
    background-image: url(${WaitingRoom});
    background-size: cover;
    
    .fa-spinner {
        font-size: 5em;
    }
`

const ContentDiv = styled.div`
    width: 100%;
    height: 100%;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

const TestInfoTextBox = styled.div`
    font-family: Open Sans,sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    padding: 1em;
    align-items: center;
    text-align: center;
    border-radius: 14px;
    background: rgba(255, 255, 255, 0.2);
    margin-top: 0.5em;
`

const LowestSpeedTextBox = styled.div`
    margin-top: 1em;
    font-family: Open Sans,sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    
`
const StyledButton = styled.button`
    visibility: ${({tryAgain, speedIsSatisfactory}) => tryAgain && !speedIsSatisfactory ? 'visible' : 'hidden'};
    margin-top: 1em;
    border-radius: 14px;
`

const submitBandwidthStatus = (download, upload, token) => fetch(process.env.REACT_APP_EPORTAL_URL + '/api/nefle_video/nefle_callback', {
    method: 'POST',
    body: JSON.stringify({download, upload, event: 102}),
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    },
});

const settings = {
    latency: {
        // Where is located your `network.php` file.
        endpoint: 'https://bandwidth.skilnet.no/network.php',
        // How many measures should be returned.
        measures: 5,
        // How much attempts to get a valid value should be done for each measure.
        attempts: 3
    },

    upload: {
        // Where is located your `network.php` file.
        endpoint: 'https://bandwidth.skilnet.no/network.php',
        // The delay while you want to take measures.
        delay: TEST_DURATION_SECONDS * 1000,

        data: {
            // The amount of data to initially use.
            size: 15 * 1024 * 1024, // 10 MB

            // If the measure period can't reach the delay defined in the settings,
            // the data amount is multiplied by the following value.
            multiplier: 1.5
        }
    },

    download: {
        // Where is located your `network.php` file.
        endpoint: 'https://bandwidth.skilnet.no/network.php',
        // The delay while you want to take measures.
        delay: TEST_DURATION_SECONDS * 1000,

        data: {
            // The amount of data to initially use.
            size: 15 * 1024 * 1024, // 10 MB

            // If the measure period can't reach the delay defined in the settings,
            // the data amount is multiplied by the following value.
            multiplier: 1.5
        }
    }
};

const lowSpeedThreshold = 1.5; // 1.5 Mbit
const mediumSpeedThreshold = 3; // 4.5 Mbit
const highSpeedThreshold = 10; // 10Mbit
export function SpeedComponentIcon({downloadSpeed, uploadSpeed, size = "6x", color = "#062A30"}) {

    const lowestSpeed = (downloadSpeed < uploadSpeed ? downloadSpeed : uploadSpeed);

    const badSpeed = lowestSpeed < lowSpeedThreshold;
    const okSpeed = lowestSpeed >= lowSpeedThreshold && lowestSpeed < mediumSpeedThreshold;
    const goodSpeed = lowestSpeed >= mediumSpeedThreshold && lowestSpeed < highSpeedThreshold;
    const greatSpeed = lowestSpeed >= highSpeedThreshold;

    return <>
        {badSpeed && <FontAwesomeIcon icon={faSignalAlt1} size={size} color={color}/>}
        {okSpeed && <FontAwesomeIcon icon={faSignalAlt2} size={size} color={color}/>}
        {goodSpeed && <FontAwesomeIcon icon={faSignalAlt3} size={size} color={color}/>}
        {greatSpeed && <FontAwesomeIcon icon={faSignalAlt} size={size} color={color}/>}
    </>;
}



const BandwidthPage = () => {
    const history = useHistory();
    const {data: {token} = {}} = useContext(DataContext)
    const [downloadSpeed, setDownloadSpeed] = useState(0);
    const [downloadActive, setDownloadActive] = useState(false);
    const [downloadEnded, setDownloadEnded] = useState(false);
    const [uploadSpeed, setUploadSpeed] = useState(0);
    const [uploadActive, setUploadActive] = useState(false);
    const [uploadEnded, setUploadEnded] = useState(false);
    const [tryAgain, setTryAgain] = useState(false);
    const [seconds, setSeconds] = useState(TEST_DURATION_SECONDS);
    const [timerActive, setTimerActive] = useState(true);
    const [uploadRestart, setUploadRestart] = useState(false);
    const [downloadRestart, setDownloadRestart] = useState(false);
    const [attempts, setAttempts] = useState(0)

        useEffect(() => {
            if (timerActive) {
                let intSec = seconds;
                const interval = setInterval(() => {
                    intSec = intSec -1;

                    if (intSec === 0){
                        clearInterval(interval);
                        setTimerActive(false)
                    }

                    setSeconds(intSec)
                }, 1000);
            }
        });

    const lowestSpeed = (downloadSpeed < uploadSpeed ? downloadSpeed : uploadSpeed);
    const lowestSpeedDisplayValue = lowestSpeed.toFixed(2);
    const downloadSpeedDisplayValue = downloadSpeed.toFixed(2);
    const uploadSpeedDisplayValue = uploadSpeed.toFixed(2);
    const speedIsSatisfactory = lowestSpeed >= lowSpeedThreshold;
    const showTestRunningText = (!tryAgain && (downloadActive || uploadActive));
    const showTestFailedText = tryAgain && !downloadActive && !uploadActive;
    const showTestSuccessText = (speedIsSatisfactory && downloadEnded && uploadEnded);

    const startBandwidthTest = () => {
        setSeconds(TEST_DURATION_SECONDS);
        setDownloadEnded(false)
        setUploadEnded(false)
        setAttempts(prev => prev + 1)

        let netDownload = new Network(settings);
        netDownload.download
            .on('start', function (dataSizeDownload) {
                setDownloadActive(true)
                if (!timerActive) {
                    setTimerActive(true)
                }
            })
            .on('progress', function (averageDownloadSpeed, instantDownloadSpeed) {
                setDownloadSpeed((averageDownloadSpeed * 8 / 1024 / 1024))
            })
            .on('restart', function (dataSizeDownload) {
                setDownloadRestart(true)
                setTimerActive(false)
                setSeconds(TEST_DURATION_SECONDS);
                setTimerActive(true)
            })
            .on('end', function (averageDownloadSpeed, allInstantDownloadSpeeds) {
                setDownloadActive(false)
                setDownloadEnded(true)
                setDownloadRestart(false)
            })
            .start();

        let netUpload = new Network(settings);
        netUpload.upload
            .on('start', function (dataSizeUpload) {
                setUploadActive(true)
                if (!timerActive) {
                    setTimerActive(true)
                }
            })
            .on('progress', function (averageUploadSpeed, instantUploadSpeed) {
                setUploadSpeed((averageUploadSpeed * 8 / 1024 / 1024))
            })
            .on('restart', function (dataSizeUpload) {
                setUploadRestart(true)
                setTimerActive(false)
                setSeconds(TEST_DURATION_SECONDS);
                setTimerActive(true)
                // The restart event is triggered when the module didn't have time
                // (according to the `delay` option) to take all the measures. A new
                // request will start with data size increased by the multiplier value.
            })
            .on('end', function (averageUploadSpeed, allInstantUploadSpeeds) {
                setUploadActive(false)
                setUploadEnded(true)
                setUploadRestart(false)

            })
            .start();

    }

    const restartBandwidthTest = () => {
        setTryAgain(false)
        setDownloadRestart(true)
        setUploadRestart(true)
        startBandwidthTest()
    }

    const goToRoom = () => {
        submitBandwidthStatus(downloadSpeed, uploadSpeed, token)
            .then(() => {
                history.replace("/room")
            })
            .catch(() => {
            })
    }

    useEffect(() => {
        if (downloadEnded && uploadEnded && (lowestSpeed >= lowSpeedThreshold)) {
            // if test has ended and minimum speed requirement is met then post speed values and proceed to room
            goToRoom()
        }

        if (downloadEnded && uploadEnded && attempts < 3 && (lowestSpeed < lowSpeedThreshold)) {
            // if test has ended and minimum speed requirement is NOT met then the user is prompted to try again
            restartBandwidthTest()
        }

        if (downloadEnded && uploadEnded && attempts === 3 && (lowestSpeed < lowSpeedThreshold)) {
            // if test has ended and minimum speed requirement is NOT met then the user is prompted to try again
            setTryAgain(true);
        }

        if (downloadEnded && uploadEnded && (lowestSpeed > lowSpeedThreshold)) {
            // if test has ended and minimum speed requirement is NOT met then the user is prompted to try again
            setTryAgain(false);
        }


    }, [downloadEnded, uploadEnded, lowestSpeed, downloadSpeed, uploadSpeed, token, history]);

    useEffect(() => {
        startBandwidthTest()
    }, []);


    return (
        <MainContainer>
            <WaitingDiv>
                <ContentDiv>
                    <SpeedComponentIcon downloadSpeed={downloadSpeed} uploadSpeed={uploadSpeed} size={"6x"}
                                        color={"#062A30"}/>
                    <LowestSpeedTextBox>{lowestSpeedDisplayValue} Mbps</LowestSpeedTextBox>
                    {(uploadRestart || downloadRestart) && attempts < 3  ? <div>Prøver på nytt...</div> : ''}
                    {showTestRunningText &&
                        <FontAwesomeIcon className={"status"} pulse={true} icon={faSpinner} size={"sm"}/>
                    }
                    <TestInfoTextBox>
                        {showTestRunningText &&
                        <div>Vennligst vent mens<br/>
                            vi tester båndbredden.<br/>
                            {(uploadRestart || downloadRestart) && attempts > 1 ? 'Forsøk '+attempts+ ' av 3' : ''}
                        </div>}
                        {showTestFailedText &&
                        <div>Båndbredden er <strong>for dårlig!</strong><br/>
                            Vennligst finn et bedre signal.<br/><br/>
                            <div>Nedlasting: {downloadSpeedDisplayValue} Mbps</div>
                            <div>Opplasting: {uploadSpeedDisplayValue} Mbps</div>
                        </div>}
                        {showTestSuccessText &&
                        <div>Båndbredden er <strong>OK!</strong><br/>
                            Du blir nå sendt videre.
                        </div>}
                    </TestInfoTextBox>
                    <StyledButton tryAgain={tryAgain} speedIsSatisfactory={speedIsSatisfactory}
                                  style={{background: '#062A30'}} onClick={goToRoom}>Fortsett likevel</StyledButton>
                </ContentDiv>
            </WaitingDiv>
        </MainContainer>
    )

}
export default BandwidthPage
