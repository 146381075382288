import React, {useContext, useEffect, useState} from 'react';
import Participant from './RoomComponents/Participant';
import {useHistory} from "react-router-dom"
import {DataContext} from "./App";
import styled from "styled-components"
import useVideo from "./hooks/useVideo";
import SettingsModal from "./RoomComponents/SettingsModal";
import {Container} from "react-bootstrap";
import VideoMenu from "./RoomComponents/VideoMenu";
import useModifiedBackground from "./hooks/useModifiedBackground";
import WaitingScreen from "./RoomComponents/WaitingScreen";
import Publication from "./RoomComponents/Publication";
import useRoomStatus from "./hooks/useRoomStatus";
import usePublicationIsTrackEnabled from "./hooks/usePublicationIsTrackEnabled";


const MainContainer = styled(Container)`
    height: calc(100% - 56px);
    padding-top: 3rem;
    padding-bottom: 3rem;

    @media (max-width: 991px) {
        padding: 0;
        max-width: none;
        
        .video-menu  {
          position: absolute;
          bottom: 0;
        }
    }
`

const StyledVideoHolder = styled.div`
    
    position: relative;
    height: calc(100% - 8rem);
    padding: 0;
    background: black;
    display: block;
    width: 100%;
    
    .videoContainer.big_video .status {
      left: inherit;
      bottom: inherit;
      top: 0;
      width: 15em;
    }
    
    & video {
      max-width: 100%;
    }

    @media (max-width: 991px){
        height: 100%;
    }
    
`

const StyledParticipants = styled.div`
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    display: grid;
    grid-auto-flow: column;
    height: 8rem;
  
    @media (max-width: 991px){
      top: calc(100vh - 17rem - 56px);
      top: calc(var(--vh, 1vh) * 100 - 17rem - 56px);
    }
    
    & > * {
      height: 8rem;
    }
`


const Room = ({onError}) => {
    const history = useHistory();
    const {data = {}, setData} = useContext(DataContext)
    const {participants, handleExitRoom, updateConfig, toggleMute, isMuted, isSharingScreen, toggleScreenSharing,room} = useVideo(data.room, data.token, onError)
    const [mainVideo, setMainVideo] = useState({participant: null, publication:null})
    const [showSettings, setShowSettings] = useState(false);
    const {status: roomStatus, participants: participantNames, refresh} = useRoomStatus(onError, room)
    const mainVideoEnabled = usePublicationIsTrackEnabled(mainVideo.publication)
    const mainParticipantState = mainVideo.participant?.state

    useModifiedBackground("#04171B")

    useEffect(() => {
        refresh()
    }, [participants, refresh])

    const toggleSettings = (show,setShow) => {
        if (showSettings) {
            setShowSettings(false)
        } else {
            setShowSettings(true)
        }
    }

    const onCancelSettings = () => {
        setShowSettings(false)
    }

    const onSaveSettings = (stream) => {
        setShowSettings(false)
        updateConfig(stream)
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (!mainVideoEnabled || !mainVideo.participant || mainParticipantState === "disconnected") {
                updateMainVideo(participants, setMainVideo)
            }
        }, 2000)

        return () => clearInterval(interval)
    }, [participants, mainParticipantState, mainVideoEnabled, mainVideo.participant])

    const onSelectVideo = (videoTrack) => {
        setMainVideo(videoTrack)
    }


    useEffect(() => {
        if (!mainVideo.participant)
            return

        const unpublishedHandler = publication => {
            if (publication === mainVideo.publication) {
                updateMainVideo(participants, setMainVideo)
            }
        }
        mainVideo.participant.on("trackUnpublished", unpublishedHandler)

        return () => mainVideo.participant.removeListener("trackUnpublished", unpublishedHandler)
    }, [mainVideo.publication, mainVideo.participant, participants, setMainVideo])

    useEffect(() => {
        if (Object.keys(data).length === 0)
            history.replace("/")

    }, [data, history])

    const handleLogout = () => {
        setData({})
        handleExitRoom()
        history.replace("/goodbye")
    };

    return (
        <MainContainer>
            <StyledVideoHolder>
                {mainVideo.publication
                    ? <Publication className={"big_video"} participantInfo={participantNames[mainVideo.participant.identity]} publication={mainVideo.publication} participant={mainVideo.participant} />
                    : <WaitingScreen roomStatus={roomStatus} />
                }
                <StyledParticipants>
                    {participants.map(p => (
                        <Participant mainVideo={mainVideo} participantInfo={participantNames[p.identity]} onSelectVideo={onSelectVideo} key={p.identity} participant={p}/>
                    ))}
                </StyledParticipants>
            </StyledVideoHolder>
            <VideoMenu
                toggleSettings={toggleSettings}
                toggleMicrophone={toggleMute}
                isMuted={isMuted}
                onLogout={handleLogout}
                onToggleShareScreen={toggleScreenSharing}
                isSharingScreen={isSharingScreen}
            />
            {showSettings && <SettingsModal handleClose={onCancelSettings} handleSave={onSaveSettings}/>}
        </MainContainer>
    );
};

export default Room;

const updateMainVideo = (participants, setMainVideo) => {
    let firstConnected = participants.find(p => p._signaling.state === "connected" && !p.isLocal);
    if (!firstConnected){
        setMainVideo({participant: null, publication: null})
        return
    }

    let firstConnectedPublication = Array.from(firstConnected.videoTracks.values())[0];
    if (!firstConnectedPublication){
        setMainVideo({participant: null, publication: null})
        return
    }

    setMainVideo({participant: firstConnected, publication: firstConnectedPublication })
}