import {isSupported} from "twilio-video";

const fetchVideoToken = async ({roomId, code,doctor, auth, path = '/api/nefle_video/token'}) => {

    const token = localStorage.getItem("nefle-video-jwt")

    const res = await fetch(process.env.REACT_APP_EPORTAL_URL + path, {
        method: 'POST',
        body: JSON.stringify({code, roomId, isSupported,doctor, auth,}),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token ? `Bearer ${token}` : ''
        },
    })

    if (res.status >= 400) {
        let error = new Error(res.statusText);
        error.code = res.status
        error.response = res

        switch (res.status) {
            case 401:
                throw new Error("Du har ikke tilatelse til å logge på dette rommet.")
            case 403:
                throw new Error("Rommet er stengt. Vennligst kom tilbake ved møte tidspunktet.")
            case 404:
                throw new Error("Koden finnes ikke.")
            default:
                throw new Error("Feilmelding fra tjener: " + error.message)
        }
    }

    return res.json()
}
export default fetchVideoToken
