import React from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDesktop, faMicrophone, faMicrophoneSlash, faPhone, faSlash, faCog} from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

const StyledVideoMenu = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    background-color: rgba(0,0,0,0.58);
    height: 8rem;
    width: 100%;
    
    button {
        margin: 1rem;
        font-size: x-large;
        border-radius: 14px;
        width: 4rem;
        height: 4rem;
    }
    
    img.flipCameraIcon {
        height: 1.8em;
        margin-top: 2px;
    }
    
    .changeSettingsBtn {
        background-color: #FFFFFF; 
        color: black;
    }
    
    .testMicBtn {
        background-color: #FFFFFF;
        color: #062a30;
    }
    
    .leaveRoomBtn {
        background-color: #B62121;
    }
`

const VideoMenu = ({toggleSettings, onLogout, toggleMicrophone, isMuted, className = "", onToggleShareScreen, isSharingScreen}) => {

    const allowSettings = false

    return <StyledVideoMenu className={className + " video-menu"}>
        {onToggleShareScreen && <button className='changeSettingsBtn' onClick={onToggleShareScreen}>
            {isSharingScreen
                ? <span className="fa-layers fa-fw">
                      <FontAwesomeIcon icon={faSlash}/>
                      <FontAwesomeIcon icon={faDesktop} transform="shrink-3"/>
                  </span>
                : <FontAwesomeIcon icon={faDesktop}/>
            }
        </button>}
        {allowSettings && <button className='changeSettingsBtn' onClick={toggleSettings}><FontAwesomeIcon icon={faCog}/></button>}
        <button className='leaveRoomBtn' onClick={onLogout}><FontAwesomeIcon icon={faPhone}/></button>
        <button className='testMicBtn' onClick={toggleMicrophone}><FontAwesomeIcon icon={isMuted ? faMicrophoneSlash : faMicrophone}/></button>
    </StyledVideoMenu>
}
export default VideoMenu