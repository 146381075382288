import {useEffect} from "react";

const useModifiedBackground = color => {
    useEffect( () => {
        let oldColor = document.body.style.backgroundColor
        document.body.style.backgroundColor = color
        return () => {
            document.body.style.backgroundColor = oldColor
        }
    }, [color]);
}
export default useModifiedBackground