import React, {useEffect, useRef, useState} from "react";
import Modal from "react-bootstrap/Modal";
import Button from "../assets/Components/Button";
import {Form, InputGroup} from "react-bootstrap";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMicrophone, faVideo} from "@fortawesome/free-solid-svg-icons";
import {faVolume} from "@fortawesome/pro-solid-svg-icons";

const StyledModal = styled(Modal)`
.modal-content {

    border-radius: 14px;
    .modal-header {
        background: #062A30;
        color: white;
        border-radius: 13px 13px 0 0;
        
        .close {
            color: #FFFFFF;
        }
        
        .modal-title {
          font-size: 16px;
          font-weight: bold;
        }
    }
    
    .input-group-text {
      background: inherit;
    }
    
    video {
      height: 12em;
      width: 100%;
      object-fit: contain;
      object-position: center;
      background: black;
    }
}
`

const SettingsModal = ({handleClose, handleSave}) => {
    const videoRef = useRef()
    const [devices, setDevices] = useState([])

    const [activeVideoInput, setActiveVideoInput] = useState(localStorage.getItem("video.device.id") ?? "" )
    const [activeAudioInput, setActiveAudioInput] = useState(localStorage.getItem("microphone.device.id") ?? "")
    const [activeAudioOutput, setActiveAudioOutput] = useState(localStorage.getItem("speaker.device.id") ?? "" )

    useEffect(() => {
        navigator.mediaDevices.enumerateDevices()
            .then(setDevices)
            .catch(function(err) {
                console.log(err.name + ": " + err.message, err);
            });
    }, [])

    useEffect(() => {
        if (!activeVideoInput)
            return

        const curElement = videoRef.current
        let constraints = {video:{deviceId: {exact: activeVideoInput}}};
        navigator.mediaDevices.getUserMedia(constraints).then(stream => {
            curElement.srcObject = stream
        });

        return () => {
            curElement.srcObject = null
        }
    }, [activeVideoInput])

    const onSave = () => {
        localStorage.setItem("video.device.id",activeVideoInput)
        localStorage.setItem("microphone.device.id",activeAudioInput)
        localStorage.setItem("speaker.device.id",activeAudioOutput)
        console.log(videoInputs.current)
        console.log(videoRef.current?.srcObject)
        handleSave(videoRef.current?.srcObject)
    }

    const videoInputs = devices.filter(d => d.kind === "videoinput");
    const audioInputs = devices.filter(d => d.kind === "audioinput");
    const audioOutputs = devices.filter(d => d.kind === "audiooutput");
    return (
        <StyledModal show={true}
                     onHide={handleClose}
                     size="sm"
                     aria-labelledby="contained-modal-title-vcenter"
                     centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Innstillinger</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <video ref={videoRef} />
                <Form>


                    <Form.Group controlId="videoinput">
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text><FontAwesomeIcon fixedWidth icon={faVideo} /></InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control custom as="select" value={activeVideoInput} onChange={(e) => setActiveVideoInput(e.target.value)}>
                                {videoInputs.map((d, index) => (
                                    <option value={d.deviceId}  key={d.deviceId + index}>{d.label}</option>
                                ))}
                            </Form.Control>
                        </InputGroup>
                    </Form.Group>

                    <Form.Group controlId="audioinput">
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text><FontAwesomeIcon fixedWidth icon={faMicrophone} /></InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control custom as="select" value={activeAudioInput} onChange={(e) => setActiveAudioInput(e.target.value)}>
                                {audioInputs.map((d, index) => (
                                    <option value={d.deviceId}  key={d.deviceId + index}>{d.label}</option>
                                ))}
                            </Form.Control>
                        </InputGroup>
                    </Form.Group>

                    {audioOutputs.length > 0 && <Form.Group controlId="audiooutput">
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text><FontAwesomeIcon fixedWidth icon={faVolume} /></InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control custom as="select" value={activeAudioOutput} onChange={(e) => setActiveAudioOutput(e.target.value)}>
                                {audioOutputs.map((d, index) => (
                                    <option value={d.deviceId}  key={d.deviceId + index}>{d.label}</option>
                                ))}
                            </Form.Control>
                        </InputGroup>
                    </Form.Group>}
                </Form>
                <div className={"text-right"}>
                    <Button variant="link" onClick={handleClose}>
                        Avbryt
                    </Button>
                    <Button variant="primary" onClick={onSave}>
                        Bruk
                    </Button>
                </div>
            </Modal.Body>

        </StyledModal>
    )
}
export default SettingsModal
