import React, {useState, useRef} from "react"
import {Link} from "react-router-dom";

const Button = React.forwardRef (({variant = "default",onClick,href,to, hidden,className = "",size = "", confirm = undefined, block = false, children, ...props}, ref) => {
    const [showConfirmation, updateShowConfirmation] = useState(false)
    const timeoutRef = useRef()
    const handleClick = (event) => {
        updateShowConfirmation(false)

        if (timeoutRef.current) {
            timeoutRef.current = clearTimeout(timeoutRef.current)
        }

        if (onClick)
            return onClick(event)
    }

    const confirmClickHandler = (e) => {
        updateShowConfirmation(true)

        timeoutRef.current = setTimeout(() => updateShowConfirmation(false),2500)

        e.preventDefault()
        e.stopPropagation()
        return false
    }

    if (hidden) return null

    size = size ? "btn-" + size : ""
    block = block ? "btn-block" : ""

    let realVariant = `${className} ${size} ${block}`
    if (variant !== "text")
        realVariant = `btn btn-${variant} ${realVariant}`


    if (to)
        return <Link
            ref={ref}
            to={to}
            onClick={(confirm && !showConfirmation) ? confirmClickHandler : handleClick}
            className={realVariant}
            {...props}
        >{confirm && showConfirmation ? confirm : children}</Link>

    if (href)
        return <a
            ref={ref}
            href={href}
            onClick={(confirm && !showConfirmation) ? confirmClickHandler : handleClick}
            className={realVariant}
            {...props}
        >{confirm && showConfirmation ? confirm : children}</a>

    return <button
        ref={ref}
        onClick={(confirm && !showConfirmation) ? confirmClickHandler : handleClick}
        className={realVariant}
        {...props}
    >{confirm && showConfirmation ? confirm : children}</button>
})
export default Button