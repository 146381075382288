import React, {useContext, useEffect} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {DataContext} from './App'
import {Col, Container, Row} from 'react-bootstrap'
import fetchToken from './hooks/fetchVideoToken'
import useBrowserSupport from './hooks/useBrowserSupport'

const ConnectCode = ({onError}) => {
    const {code = null} = useParams()
    const history = useHistory()
    const valid = useBrowserSupport(onError)
    const {setData} = useContext(DataContext)

    useEffect(() => {
        if (!code || !valid) return

        localStorage.setItem('uri', '/c/' + code)
        let mounted = true
        fetchToken({code})
            .then(data => {
                if (!mounted) return

                setData(data)
                history.push('/bandwidth')
            })
            .catch(onError)

        return () => {
            mounted = false
        }
    }, [code, onError, history, setData, valid])

    return (
        <Container fluid>
            <Row>
                <Col md={{span: 6, offset: 3}}>
                    <div style={{marginTop: '40%'}}>
                        <h2>
                            <strong>Kobler til...</strong>
                        </h2>
                        <div style={{textAlign: 'center'}}>
                            <p>Vennligst vent mens vi logger deg på videorommet...</p>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}
export default ConnectCode
