import React from "react";
import {Container, Row, Col} from "react-bootstrap";


const Goodbye = () => {

    const onLogout = () => {
        localStorage.removeItem("nefle-video-jwt");
    }

    return (
        <Container fluid>
            <Row>
                <Col md={{ span: 6, offset: 3 }}>
                    <div style={{marginTop:'40%'}}>
                        <h2><strong>Du har forlatt rommet</strong></h2>
                        <div style={{textAlign:'center'}}>
                            <p>Takk for at du deltok på videokonsultasjonen.</p>
                            <a onClick={onLogout} href={process.env.REACT_APP_EPORTAL_URL + "/logout"}>Log ut</a>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}
export default Goodbye
