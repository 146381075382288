import {useEffect, useRef, useState, useCallback} from "react";
import Video from "twilio-video";
import IgnoredError from "../assets/IgnoredError";

export const ROOM_EVENTS = [
    'disconnected','connected','dominantSpeakerChanged', 'participantConnected', 'participantDisconnected',
    'reconnected', 'reconnecting', 'recordingStarted','recordingStopped',
    'trackDimensionsChanged', 'trackDisabled', 'trackEnabled', 'trackMessage', 'trackPublished', 'trackPublishPriorityChanged',
    'trackStarted','trackSubscriptionFailed', 'trackSwitchedOff', 'trackSwitchedOn', 'trackUnpublished',
    'trackSubscribed', 'trackUnsubscribed',
]

export const PARTICIPANT_EVENTS = ['trackSubscribed', 'trackUnsubscribed', 'trackDimensionsChanged', 'trackDisabled', 'trackEnabled',
    'trackPublicationFailed', 'trackPublished', 'trackStarted', 'trackStopped', 'disconnected', 'networkQualityLevelChanged',
    'trackMessage', 'trackPublishPriorityChanged', 'trackSubscriptionFailed', 'trackSwitchedOff',
    'trackSwitchedOn', 'trackUnpublished'
];

export const TRACK_EVENTS = ['dimensionsChanged', 'disabled', 'enabled', 'started', 'stopped', 'switchedOff', 'switchedOn'];

const useVideo = (roomId, token, onError) => {
    const [participants, setParticipants] = useState([]);
    const [state, setState] = useState("Waiting for approval")
    const roomRef = useRef();
    const localParticipant = roomRef.current?.localParticipant ?? null
    const [mute, setMute] = useState(false)
    const [screenStream, setScreenStream] = useState([])

    const handleExitRoom = useCallback(() => {
        if (roomRef.current)
            roomRef.current.disconnect()

        if (localParticipant) {
            localParticipant.tracks.forEach(t => {
                t.unpublish()
                t.track.stop()
            })
        }
    }, [localParticipant])

    const toggleMute = () => {
        if (!localParticipant || !localParticipant.audioTracks)
            return


        if (mute) {
            localParticipant.audioTracks.forEach(t => t.track.enable())
            setMute(false)
        } else {
            // mute
            localParticipant.audioTracks.forEach(t => t.track.disable())
            setMute(true)
        }

    }


    const updateConfig = (videoStream, microphoneStream, speakerStream) => {
        if (!localParticipant)
            return


        if (videoStream) {

            localParticipant.videoTracks.forEach(publishedTrack => {
                publishedTrack.unpublish()
                publishedTrack.track.detach()
                publishedTrack.track.stop()
                publishedTrack.track.disable()
            })

            const videoTrack = new Video.LocalVideoTrack(videoStream)
            const publishedVideoTrack = localParticipant.publishTracks(videoTrack)
            console.log("Published video track", publishedVideoTrack)
        }

        if (microphoneStream) {
            console.log("Refreshing Microhpone stream", microphoneStream)
            localParticipant.audioTracks.forEach(publishedTrack => {
                publishedTrack.unpublish()
                publishedTrack.track.detach()
                publishedTrack.track.stop()
                publishedTrack.track.disable()
            })
            const audioTrack = new Video.LocalVideoTrack(microphoneStream)
            const publishedMicrophoneTrack = localParticipant.publishTracks(audioTrack)
            console.log("Published microphone track", publishedMicrophoneTrack)
        }
    }

    const toggleScreenSharing = async () => {
        if (!localParticipant)
            return

        if (screenStream.length > 0) {
            screenStream.forEach(publication => {
                localParticipant.unpublishTrack(publication.track)
                localParticipant.emit('trackUnpublished', publication);
                publication.track.stop()
            })

            setScreenStream([])
            console.log ("Stops sharing...", screenStream)
        } else {
            const screenStream = await navigator.mediaDevices.getDisplayMedia({audio: false, video: true,})
            const screenPublication = await localParticipant.publishTracks(screenStream.getTracks())
            setScreenStream(screenPublication)
            console.log ("Start sharing...", screenPublication)
        }
    }

    useEffect(() => {

        const fn = (event) => () => {
            console.log(event)
            handleExitRoom()
        }

        window.addEventListener("beforeunload", fn("onBeforeUnload"))
        window.addEventListener("unload", fn("onUnload"))

    }, [handleExitRoom])

    useEffect(() => {
        if (!roomId || !token)
            return

        const participantConnected = p => {
            p.isLocal = false

            setParticipants(prevParticipants => [p, ...prevParticipants]);
        };
        const participantDisconnected = p =>  {
            setParticipants(prevParticipants => prevParticipants.filter(prevP => prevP !== p));
            setState("Peer disconnected")
        };


        const videoDeviceId = localStorage.getItem("video.device.id")
        const microphoneDeviceId = localStorage.getItem("microphone.device.id")
        // const speakerDeviceId = localStorage.getItem("speaker.device.id")

        const constraints = {
            video: { facingMode: "user" },
            audio: true,
        }
        if (videoDeviceId)
            constraints.video = {deviceId: {exact: videoDeviceId}}

        if (microphoneDeviceId)
            constraints.audio = {deviceId: {exact: microphoneDeviceId}}

        console.log("Starting video", constraints)
        Video.createLocalTracks(constraints)
            .then(
                localTracks => {
                    setState("Got mic/video approval")
                    return Video.connect(token, {name: roomId, tracks: localTracks, region: 'de1'})
                },
                error => {
                    setState("Could not get mic/video approval")

                    return Video.createLocalTracks({audio: true, video: { facingMode: "user" }}).then(localTracks => {
                        setState("Got mic/video approval")
                        return Video.connect(token, {name: roomId, tracks: localTracks, region: 'de1'})
                    }).catch(err => {
                        setState("Could not get mic/video approval")
                        return Video.createLocalTracks({audio: true, video: true}).then(localTracks => {
                            setState("Got mic/video approval")
                            return Video.connect(token, {name: roomId, tracks: localTracks, region: 'de1'})
                        })
                    })
                }
            )
            .then(room => {
                roomRef.current = room
                room.on('participantConnected', participantConnected);
                room.on('participantDisconnected', participantDisconnected);
                room.localParticipant.isLocal = true
                setParticipants(participants => [...participants, room.localParticipant])
                room.participants.forEach(participantConnected);
                setState("Connected to room")
            },
            error => {
                console.log(error)
                if (error.code === 53105) {
                    onError(new IgnoredError("For mange deltakere (det er plass til maks 4 deltakere)"))
                } else {
                    onError(error)
                }
            }
        );

        return () => {
            if (roomRef.current && roomRef.current.localParticipant.state === "connected") {
                roomRef.current.disconnect();
            }
        };
    }, [ token, roomId, onError]);

    return {
        participants,
        handleExitRoom,
        state,
        hasMultipleCameras: false, //true, // cameraCount > 1,
        updateConfig,
        isMuted: mute,
        toggleMute,
        toggleScreenSharing: navigator.mediaDevices?.getDisplayMedia ? toggleScreenSharing : undefined,
        isSharingScreen: screenStream.length > 0,
        room: roomRef.current
    }
}
export default useVideo