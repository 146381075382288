import {Alert, Col, Container, Row} from "react-bootstrap";
import React, {useContext, useEffect} from "react";
import {useHistory} from "react-router-dom";
import {DataContext} from "./App";


const ErrorPage = ({clearError, error}) => {
    const history = useHistory();
    const {data: {token} = {}} = useContext(DataContext)

    if (error && error.type !== "IgnoredError")
        console.error(history.location.pathname, error)

    useEffect(() => {
        if (!token || !error)
            return

        fetch(process.env.REACT_APP_EPORTAL_URL + '/api/nefle_video/nefle_callback', {
            method: 'POST',
            body: JSON.stringify({message: error.message, event: 101}),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        }).then(() => {}).catch(() => {})
    }, [token, error])

    useEffect(() => {
        if (window.onerror && error && error.type !== "IgnoredError") {
            window.onerror(null, null, null, null, error);
        }
    }, [error])

    const onReturn = () => {
        clearError()
        history.push("/")
    }

    return <Container fluid>
        <Row>
            <Col md={{ span: 6, offset: 3 }}>
                <div style={{marginTop:'40%'}}>
                    <Alert variant={"danger"}>
                        Feilmelding: {error?.message ?? "Ukjent"}
                    </Alert>
                    <button style={{background:'#A61E7B'}} onClick={onReturn} >Gå tilbake</button>
                </div>
            </Col>
        </Row>
    </Container>
}
export default ErrorPage