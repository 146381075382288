import {useEffect, useState} from "react";

const useParticipantStatus = participant => {
    const [state, setState] = useState("connected")
    const [muted, setMuted] = useState(false)

    useEffect(() => {
        if (!participant)
            return

        const interval = setInterval(() => {
            const currentState = participant?._signaling.state ?? null
            if (currentState !== state)
                setState(currentState)

            let audioTracks = Array.from(participant.audioTracks.values());
            let hasNoAudioTracks = !audioTracks.find(t => t.isTrackEnabled)

            if (hasNoAudioTracks !== muted)
                setMuted(hasNoAudioTracks)

        }, 1000)

        return () => clearInterval(interval)
    })

    return {state, muted}
}
export default useParticipantStatus