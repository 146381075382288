import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import styled from "styled-components";
import WaitingRoom from "../assets/image/waitingroom_background.jpg";

const WaitingDiv = styled.div `
    position: absolute;
    width: 100%;
    height: 100%;
    
    h3 {
        margin: 1em 2em;
        text-align: center;
    }
    
    
    color: black;
    
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
    background-image: url(${WaitingRoom});
    background-size: cover;
    
    .fa-spinner {
        font-size: 5em;
    }
`

const WaitingScreen = ({roomStatus}) => {
    return (
        <WaitingDiv>
            <FontAwesomeIcon className={"status"} pulse={true} icon={faSpinner}/>
            <h3 className={"status"}>{roomStatus}</h3>
        </WaitingDiv>
    )
}
export default WaitingScreen