import React, {useContext, useEffect} from 'react'
import {useHistory, useLocation, useParams} from 'react-router-dom'
import {DataContext} from './App'
import {Col, Container, Row} from 'react-bootstrap'
import fetchToken from './hooks/fetchVideoToken'
import useLogin from './hooks/useLogin'
import useBrowserSupport from './hooks/useBrowserSupport'

const ConnectDoctor = ({onError}) => {
    const {doctor} = useParams()
    localStorage.setItem('doctor', doctor)
    const history = useHistory()
    const {search} = useLocation()
    const auth = (new URLSearchParams(search)).get("auth")
    const valid = useBrowserSupport(onError)
    const {login, token} = useLogin(onError,false, "nefle-video-guest")

    useEffect(() => {
        if (!valid || !doctor)
            return

        const hostname = window.location.host
        const protocol = window.location.protocol
        if (!token) login(new URL(`${protocol}//${hostname}/authenticate`))

        localStorage.setItem('uri', '/l/' + doctor + (auth ? `?auth=${auth}` : ""))
        history.replace('/authenticate')
    }, [token, history, login, doctor, auth, valid])

    return (
        <Container fluid>
            <Row>
                <Col md={{span: 6, offset: 3}}>
                    <div style={{marginTop: '40%'}}>
                        <h2>
                            <strong>Kobler til...</strong>
                        </h2>
                        <div style={{textAlign: 'center'}}>
                            <p>Autentiserer...</p>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}
export default ConnectDoctor

const Auth = ({onError}) => {
    const {setData} = useContext(DataContext)
    const doctor = localStorage.getItem('doctor')
    const {token} = useLogin(onError, true)
    const history = useHistory()
    const valid = useBrowserSupport(onError)

    useEffect(() => {
        if (!token || !valid) return

        let mounted = true
        fetchToken({doctor, token, path: '/api/nefle_video/authenticated_token'})
            .then(data => {
                if (!mounted) return

                setData(data)
                history.push('/bandwidth')
            })
            .catch(onError)

        return () => {
            mounted = false
        }
    }, [token, history, onError, doctor, setData, valid])

    return (
        <Container fluid>
            <Row>
                <Col md={{span: 6, offset: 3}}>
                    <div style={{marginTop: '40%'}}>
                        <h2>
                            <strong>Kobler til...</strong>
                        </h2>
                        <div style={{textAlign: 'center'}}>
                            <p>Henter videorommet...</p>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}
ConnectDoctor.Auth = Auth
